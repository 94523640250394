import React from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'

const images = [
    require('./../../images/gallery/about/s12.jpg'),
    require('./../../images/gallery/about/s11.jpg'),
    require('./../../images/gallery/about/s1.jpg'),
    require('./../../images/gallery/about/s3.jpg'),
    require('./../../images/gallery/about/s7.jpg'),
    require('./../../images/gallery/about/s6.jpg')    
]

var bnr1 = require('./../../images/background/bg-map.png');
var bnr2 = require('./../../images/background/bg5.jpg');

class About2 extends React.Component {
    render() {
        const options = {
            loop:true,
            autoplay:true,
            margin:30,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                991:{
                    items:1
                }
            }
        };
        return (
            <>
                <div className="section-full p-t20 p-b4 mobile-page-padding" >
                    <div className="services-half-section-top" style={{ backgroundImage: `url(${bnr1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} >
                        <div className="section-content" style={{margin:'auto'}}>
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center text-wh">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Company</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="container-fluid" style={{ textAlign:'center',margin:'auto'}}>
                           
                                <div className="row">                     
                                    <div className="col-md-4 col-sm-6" >
                                        <div className="text-primary mt-icon-box-wraper m-b30">
                                            <span className="icon-md p-t10">
                                                
                                                    <FontAwesomeIcon icon={faSolarPanel} size="3x" /> 
                                            </span>
                                            <div className="icon-content text-light-blue">
                                                <div className="counter font-50 font-weight-600 m-b5"><CountUp end={52} duration={17} />+</div>
                                                <span className="font-16">Projects Completed</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6" >
                                        <div className="text-primary mt-icon-box-wraper m-b30">
                                            <span className="icon-md p-t10">
                                                
                                                    <FontAwesomeIcon icon={faUserTie} size="3x" />
                                            </span>
                                            <div className="icon-content text-light-blue">
                                                <div className="counter font-50 font-weight-600 m-b5"><CountUp end={48} duration={17} />+</div>
                                                <span className="font-16">Happy Clients</span>
                                            </div>
                                        </div>
                                    </div>                                 
                                    <div className="col-md-4 col-sm-6" >
                                        <div className="text-primary mt-icon-box-wraper m-b30">
                                            <span className="icon-md p-t10">
                                                <FontAwesomeIcon icon={faBolt} size="3x" />
                                            </span>
                                            <div className="icon-content text-light-blue">
                                                <div className="counter font-50 font-weight-600 m-b5"><CountUp end={501} duration={15} />MWp</div>
                                                <span className="font-16">Installation of solar systems</span>
                                            </div>
                                        </div>
                                    </div>                                                                
                                </div>                      
                            </div>


                            <div className="section-content">    
                                  
                                <div className="row" style={{marginLeft:0}}>
                                    <div className="col-lg-6 col-md-12 col-sm-12 ">
                                    {/* <div className="col-md-4 col-sm-6"> */}
                                        <OwlCarousel className="owl-carousel about-home about-home-v2 owl-dots-bottom-left" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <div className="mt-img-effect zoom-slow">
                                                    <NavLink to="/about"><img src={item} style={{width:'40dvw',height:'40dvh'}} alt="Plant img" /></NavLink>
                                                    </div>
                                                </div>
                                                
                                            ))}
                                            
                                        </OwlCarousel>
                                    </div>
                                    <div className="col-lg-5 col-md-11 col-sm-11" style={{marginRight:1}}>
                                    {/* <div className="col-md-4 col-sm-6"> */}
                                        <div className="about-home-right about-right-v2 bg-primary text-black p-a30">
                                            <h3 className="m-t0"><span className="font-weight-100">Committed</span> to top-quality solar power solutions.</h3>
                                            <p><strong>GeWiS Renewpower Pvt. Ltd. is headquartered in Pune, Maharashtra. The company has installed solar power plants for industrial and commercial consumers in multiple states across India.</strong></p>
                                            <p><strong>We offer turnkey solar solutions to market leaders in various sectors. These include engineering, automobiles, steel, polymer, chemicals, real estate, and more.</strong></p>
                                            <div className="text-left">
                                            <NavLink to="/about" className="site-button-secondry btn-effect">Read More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                    <div className="services-half-section-bottom p-t80 p-b50  bg-secondry bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bnr2})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} >
                        
                    </div>
                </div>
            </>
        );
    }
};

export default About2;