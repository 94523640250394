import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
    { label: "Construction", filter: ".cat-1" },
    { label: "Renovation", filter: ".cat-2" },
    { label: "Outdoor", filter: ".cat-3" },
    { label: "Interiors", filter: ".cat-4" },
    { label: "Consulting", filter: ".cat-5" },

];

const projects = [
    {
        image: require('./../../images/gallery/about/s12.jpg'),
        title: 'Bori 13.5MWp',
        address: 'Parbhani Maharashtra',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/gallery/about/s6.jpg'),
        title: 'Victory Precisions Pvt. Ltd.',
        address: 'Chakan',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/gallery/about/s3.jpg'),
        title: 'Tepsol 13.5 MWp',
        address: 'Sonkhed, Maharashtra',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/gallery/about/s2.jpg'),
        title: 'Azure Power',
        address: 'Rajasthan',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/gallery/about/s1.jpg'),
        title: 'Sansera Engineering Pvt. Ltd.',
        address: 'Pune',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/gallery/about/s9.jpg'),
        title: 'Enfinity Global',
        address: 'Maharashtra',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/gallery/about/s11.jpg'),
        title: 'Manvat 13.5MWp',
        address: 'Maharashtra',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/gallery/about/s7.jpg'),
        title: 'Yeoti 13.5MWp',
        address: 'Solapur Maharashtra',
        filter: 'cat-2'
    },
]

var img1 = require('./../../images/background/bg5.jpg');

class OurProject3 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    render() {
        const columnCount = 4; // Number of columns

        return (
            <>
                <div className="section-full p-t80 p-b50 bg-cover bg-parallax mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')', backgroundSize: 'cover' }}>
                    <div className="container" style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Projects</h2>
                                </div>
                            </div>
                            </div>
                            {/* TITLE END bg-parallax*/}                          
                            <div className="filter-wrap p-b15 text-white text-center">
                                <h4>Our core niche lies in delivering effective, cost efficient, and high quality solar power plants for tier 1 and multi-national industrial and commercial power consumers</h4>
                            </div>
                            {/* PAGINATION END */}
                            {/* GALLERY CONTENT START */}                           
                            <div className="portfolio mfp-gallery work-grid row clearfix" style={{ position: 'relative', height:'auto'  }}>
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-md-${12 / columnCount} col-sm-6 m-b30`}>
                                        <div className="mt-box image-hover-block">
                                            <div className="mt-thum-bx">
                                                <img src={item.image} alt="" style={{ maxWidth: '100%', height: 'auto' }} loading='lazy'/>
                                            </div>
                                            <div className="mt-info p-t20 text-white">
                                                <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                                                <p className="m-b0">{item.address}</p>
                                            </div>
                                            <NavLink to="/project-grid"></NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* GALLERY CONTENT END */}
                            <div className="text-center">
                            <NavLink to="/Project-grid" className="site-button btn-effect">See All Projects</NavLink>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
};

export default OurProject3;