import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

const projects = [  
    {
        slug: 'victory-precision',
        image: require('./../../images/projects/square/Victory.jpg'),
        title: 'Victory Precision Pvt. Ltd',
        description: 'Pune',
        filter: 'cat-2'
    },
    {
        slug: 'advik-patnagar',
        image: require('./../../images/projects/square/Advik-p.jpg'),
        title: 'Advik Hi-Tech Pvt.Ltd',
        description: 'Patnagar'
    },   
    {
        slug: 'sansera',
        image: require('./../../images/projects/square/sansera.jpg'),
        title: 'Sansera Engineering Pvt Ltd',
        description: 'Pune'
    },
    {
        slug: 'zamil',
        image: require('./../../images/projects/square/Zamil.jpg'),
        title: 'Zamil Steel Buildings India Pvt. Ltd',
        description: 'Pune'
    },
    {
        slug: 'biorad',
        image: require('./../../images/projects/square/Biorad.jpg'),
        title: 'Biorad Medisys',
        description: 'Pune'
    },
    {
        slug: 'advik-b',
        image: require('./../../images/projects/square/Advik-b.jpg'),
        title: 'Advik Hi-Tech Pvt. Ltd',
        description: 'Bengaluru'
    },
    {
        slug: 'appl',
        image: require('./../../images/projects/square/APPL-pune.jpg'),
        title: 'APPL GOR Plastics India Pvt. Ltd',
        description: 'Pune'
    }
]

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Broad vision. Careful thought. Hand-crafted design." pagename="Case Studies" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                            <div className="section-content">
                                <div className="work-carousel-outer">
                                    <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                    
                                        {projects.map((item, index) => (
                                            <div key={index} className="item mt-box">
                                                <div className="mt-img-effect mt-img-overlay7">
                                                    <img src={item.image} alt="" />
                                                </div>                                              
                                                <div className="mt-info p-a30">
                                                    <h4 className="m-b20 m-t0"><NavLink to={`/${item.slug}`}>{item.title}</NavLink></h4>
                                                    <p>{item.description}</p>
                                                    <NavLink to={`/${item.slug}`} className="site-button btn-effect">Read More</NavLink>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            </>
        );
    };
};

export default ProjectCarousel;