import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg2.jpg');
var sc_softs = require('./../../images/projects/square/turenkey.jpg');
var sc_softs_2 = require('./../../images/projects/square/energyaudit.jpg');
var sc_softs_3 = require('./../../images/projects/square/designengg.jpg');
var sc_softs_4 = require('./../../images/projects/square/projmng.jpg');
var sc_softs_5 = require('./../../images/projects/square/opmaint.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Services" pagename="SERVICES" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">
                                                     
                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_2} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                <div style={{ textAlign: 'left' }}>
                                                <h4 style={{ fontWeight: 'bold' }}>Energy Audit Services by GeWiS Renewpower</h4>
                                                </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                            <p>At GeWiS Renewpower, we offer comprehensive energy audit services designed to optimize your energy consumption and reduce operational costs. Our expert team conducts detailed assessments to identify inefficiencies and recommend solutions for improved energy performance.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='' style={{margin:'auto', textAlign:'Left', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                <div style={{marginLeft:'6px', marginRight:'6px'}}>                               
                                <h4>Detailed Energy Assessment</h4>
                                <p>Our energy audit process begins with a thorough evaluation of your facility’s energy usage. This includes:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Site Inspection: Comprehensive inspection of all energy-consuming systems and equipment.</li>
                                        <li>Data Collection: Gathering data on energy usage patterns and identifying high-consumption areas.</li>
                                        <li>Performance Analysis: Detailed analysis of energy performance to identify inefficiencies and potential savings.</li>
                                    </ul>
                                <h4>Customized Recommendations</h4>
                                <p>Based on our assessment, we provide tailored recommendations to enhance energy efficiency. These include:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Energy Conservation Measures: Identification of opportunities for energy savings through improved practices and technologies.</li>
                                        <li>System Upgrades: Recommendations for upgrading to more energy-efficient systems and equipment.</li>
                                        <li>Renewable Energy Solutions: Evaluation of potential renewable energy solutions to reduce reliance on traditional energy sources.</li>
                                    </ul>
                                <h4>Implementation Support</h4>
                                <p>GeWiS Renewpower offers support throughout the implementation of recommended measures, ensuring a seamless transition to more efficient energy use. Our services include:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Project Management: Overseeing the implementation of energy-saving measures to ensure timely and effective execution.</li>
                                        <li>Technical Support: Providing technical assistance and expertise during the installation and integration of new systems.</li>
                                        <li>Monitoring and Verification: Continuous monitoring of energy performance post-implementation to ensure sustained savings.</li>
                                    </ul>
                                <h4>Benefits of Our Energy Audit Services</h4>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Cost Savings: Significant reduction in energy costs through optimized energy usage and efficient systems.</li>
                                        <li>Sustainability: Improved environmental performance by reducing carbon footprint and promoting sustainable energy practices.</li>
                                        <li>Enhanced Performance: Increased operational efficiency and reliability of energy systems.</li>
                                    </ul>
                                
                                
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;