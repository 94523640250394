import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg1.jpg');
var sc_softs = require('./../../images/projects/square/turenkey.jpg');
var sc_softs_2 = require('./../../images/projects/square/energyaudit.jpg');
var sc_softs_3 = require('./../../images/projects/square/designengg.jpg');
var sc_softs_4 = require('./../../images/projects/square/projmng.jpg');
var sc_softs_5 = require('./../../images/projects/square/opmaint.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Services" pagename="SERVICES" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">                        
                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_5} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h4 style={{ fontWeight: 'bold' }}>Operations & Maintenance Services by GeWiS Renewpower</h4>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                            <p>At GeWiS Renewpower, we offer operations and maintenance (O&M) services to ensure the long-term performance and reliability of your solar power systems. Our expert team is dedicated to maximizing the efficiency and lifespan of your solar installations through proactive maintenance, real-time monitoring, and rapid response to any issues that may arise.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='' style={{margin:'auto', textAlign:'Left', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                <div style={{marginLeft:'6px', marginRight:'6px'}}>
                                
                                <h4>Proactive Maintenance</h4>
                                <p>Regular and proactive maintenance is crucial for the optimal performance of solar power systems. Our team conducts routine inspections, cleaning, and servicing of all system components to prevent potential issues and maintain peak efficiency. This includes:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Inspection and Cleaning: Regularly scheduled inspections and cleaning to remove dust, debris, and any obstructions that could affect performance.</li>
                                        <li>Component Testing: Thorough testing of MC4 Connectors, inverters, panels, cables to ensure they are functioning correctly and efficiently.</li>
                                        <li>Preventive Repairs: Identifying and addressing potential issues before they become significant problems, reducing downtime and repair costs.</li>
                                    </ul>
                                <h4>Real-Time Monitoring</h4>
                                <p>Our advanced monitoring systems provide real-time data on the performance of your solar power systems. This allows us to quickly identify and diagnose any issues, ensuring swift corrective action. Key features of our monitoring services include:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Performance Tracking: Continuous tracking of system performance metrics to ensure optimal energy production.</li>
                                        <li>Fault Detection: Immediate detection and alerting of any faults or abnormalities in the system.</li>
                                        <li>Remote Diagnostics: The ability to perform remote diagnostics and troubleshooting to address issues quickly.</li>
                                    </ul>
                                <h4>Rapid Response and Repair</h4>
                                <p>In the event of a system malfunction, our rapid response team is ready to take immediate action. We understand the importance of minimizing downtime, and our team is equipped to provide prompt and efficient repairs. Our services include:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Emergency Support: 24/7 emergency support to address critical issues as they arise.</li>
                                        <li>On-Site Repairs: Swift dispatch of technicians to perform on-site repairs and maintenance.</li>
                                        <li>Spare Parts Management: Maintaining a stock of essential spare parts to ensure quick replacements and repairs.</li>
                                    </ul>
                                <h4>Performance Optimization</h4>
                                <p>Beyond maintenance and repairs, we focus on continuous performance optimization of your solar power systems. Our team regularly evaluates system performance and implements improvements to enhance energy production and efficiency. This includes:</p>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>System Upgrades: Identifying opportunities for system upgrades and enhancements.</li>
                                        <li>Energy Audits: Conducting energy audits to ensure the system is meeting performance expectations.</li>
                                        <li>Efficiency Improvements: Implementing strategies to improve overall system efficiency and reduce energy loss.</li>
                                    </ul>
                                <h4>Why Choose GeWiS Renewpower for Operations and Maintenance?</h4>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Expert Team: Our experienced technicians and engineers are dedicated to maintaining the highest standards of service.</li>
                                        <li>Comprehensive Services: From routine maintenance to emergency repairs, we cover all aspects of O&M.</li>
                                        <li>Advanced Monitoring: Utilizing state-of-the-art monitoring systems for real-time performance tracking and diagnostics.</li>
                                        <li>Rapid Response: Quick and efficient response to any issues, minimizing downtime and ensuring continuous operation.</li>
                                        <li>Performance Focused: Committed to maximizing the efficiency and longevity of your solar power systems.</li>
                                    </ul>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;