import React from 'react';
import { NavLink } from 'react-router-dom';

class Faqss extends React.Component {
    render() {
        return (
            <>
                
                <div className="page-content">
               
                    
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container"  style={{margin:'auto'}}>
                            <div className="faq-1 bg-gray  p-a50">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="mt-separator-outer separator-left">
                                        <div className="mt-separator">
                                            <h2 className="text sep-line-one ">FAQs </h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* Accordian */}
                                <div className="mt-accordion acc-bg-white" id="accordion5">
                                    <div className="panel mt-panel">
                                        <div className="acod-head acc-actives">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                What is a solar power plant? 
                                        <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne5" className="acod-body collapse in">
                                            <div className="acod-content p-tb15">
                                            <p><strong>A solar power plant is a large-scale facility that converts sunlight into electricity using photovoltaic (PV) panels. These plants can range from small-scale rooftop systems to massive utility-scale installations.</strong></p>
                                           
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                How does a solar power plant work? 
                                        <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                            <p><strong>Solar panels in a plant capture sunlight and convert it into direct current (DC) electricity. This DC electricity is then converted into alternating current (AC) using inverters, which can be fed into the electrical grid.</strong></p>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                What are the different types of solar power plants?
                                        <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p><strong>Utility-scale solar plants:</strong> These are the largest type of solar power plants, often located in remote areas with ample sunlight.</p>
                                                <p><strong>Rooftop Solar Systems:</strong> These are smaller systems installed on industrial roofs, residential or commercial buildings to meet their own energy needs.</p>   
                                                <p><strong>Ground-Mounted Solar Systems:</strong> Solar panels mounted on the ground, often used in areas with limited rooftop space or where larger installations are more efficient. These systems can be used for both residential and commercial applications. </p> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                How do I determine the appropriate size of a solar power plant? 
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFour5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p><strong>The size of a solar power plant depends on factors such as the desired power output, and the project's specific needs. GeWiS solar experts can help you determine the optimal size.</strong></p>
                                                                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                What factors affect the cost of a solar power plant?
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>The cost of a solar power plant is influenced by several factors, including the size of the system, the type of panels used, the installation costs, and government incentives or subsidies.</strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseSix5" className="collapsed" data-parent="#accordion5">
                                                How long does it take to install a solar power plant? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseSix5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>The installation time for a solar power plant can vary depending on the size and complexity of the project. Large-scale utility plants can take several months to construct, while smaller commercial or residential systems may be installed in a few days.</strong></p>                                                  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseSeven5" className="collapsed" data-parent="#accordion5">
                                                How efficient are solar power plants? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseSeven5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>The efficiency of a solar power plant depends on factors such as the type of panels, the installation location, and the overall system design.</strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseEight5" className="collapsed" data-parent="#accordion5">
                                                Do solar power plants require regular maintenance? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseEight5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>Solar power plants generally require minimal maintenance, but periodic inspections and cleaning are recommended to ensure optimal performance. </strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseNine5" className="collapsed" data-parent="#accordion5">
                                                What is the lifespan of a solar power plant? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseNine5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>Solar panels typically have a lifespan of 25-30 years, and with proper maintenance, solar power plants can continue to operate efficiently for many years.</strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseTen5" className="collapsed" data-parent="#accordion5">
                                                What are the key benefits of Solar power?
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTen5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>It provides us with a renewable source of clean energy, reducing electricity bills. </strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseEleven5" className="collapsed" data-parent="#accordion5">
                                                What are the environmental benefits of solar power plants? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseEleven5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>They offer several environmental benefits, including reduced carbon footprints, improved air quality, preservation of natural resources. </strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseTwelve5" className="collapsed" data-parent="#accordion5">
                                                Can solar power plants work at night? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwelve5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>No, They rely on sunlight to generate electricity, and without sunlight, the photovoltaic cells cannot produce power., but energy storage systems like batteries can store excess energy at night. </strong></p>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseThirteen5" className="collapsed" data-parent="#accordion5">
                                                What is net metering? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThirteen5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>Net Metering is a billing system that allows solar energy system owners to offset their electricity usage with the excess electricity they generate. </strong></p>
                                            <p><strong>A system that allows excess energy produced to be sent to the grid, earning credits for future use.</strong></p>        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFourteen5" className="collapsed" data-parent="#accordion5">
                                                What is the difference between on-grid and off-grid solar power plants? 
                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFourteen5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>On-grid plants are connected to the local utility, while off-grid systems are independent and use storage batteries.</strong></p>
                                           <table style={{ border: '1px solid #dddddd'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Feature</th>
                                                        <th>On-grid Solar Power Plant</th>
                                                        <th>Off-grid Solar Power Plant</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Grid connection</td>
                                                        <td>Connected</td>
                                                        <td>Not connected</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Energy storage</td>
                                                        <td>Grid acts as storage</td>
                                                        <td>Batteries</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cost</td>
                                                        <td>Lower upfront cost</td>
                                                        <td>Higher upfront cost</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Energy independence</td>
                                                        <td>Partial</td>
                                                        <td>Complete</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reliability</td>
                                                        <td>Dependent on grid </td>
                                                        <td>Independent</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Suitable for</td>
                                                        <td>Areas with reliable grid</td>
                                                        <td>Remote locations or areas with frequent power outages</td>
                                                    </tr>
                                                </tbody>
                                            </table>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

            </>
        );
    }
};

export default Faqss;