import React from "react";
import { NavLink } from "react-router-dom";

var bgimage = require("./../../images/background/bg-site.png");

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = {
            // logo: require("./../../images/Gewis_Transparent_Custosm_Logo.png"),
            logo: require("./../../images/gewissky1.png"),
        };
    }

    updateFooterLogo = (updatedlogo) => {
        this.currentstate.logo = updatedlogo;
    };

    render() {
        return (
            <>
                <footer className="site-footer footer-large  bg-gewis	footer-wide text-white">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container" style={{margin:"auto"}}>
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about ">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15 ">
                                            <NavLink to={"./"}>
                                                <img
                                                    src={this.currentstate.logo}
                                                    alt="" loading="lazy" style={{maxWidth:"100%",maxHeight:"100%"}}
                                                />
                                            </NavLink>
                                        </div>
                                        <p className="max-w400  ">
                                            Our goal is to fulfil the wishes of
                                            our customers at a high level. We
                                            happily accept new challenges. We
                                            grow with our projects.
                                        </p>
                                        <ul className="social-icons  mt-social-links">
                                            <li>
                                                <NavLink
                                                    to={"https://gewisrenew.com/"}
                                                    className="fa fa-google"
                                                />
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={"https://www.youtube.com/channel/UCx0II0Y2qyLf-Qbg1Pehacw"}
                                                    className="fa fa-youtube"
                                                />
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={"https://www.facebook.com/Gewis-Renewpower-Pvt-Ltd-1500429330039239/"}
                                                    className="fa fa-facebook"
                                                />
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={"https://twitter.com/i/flow/login?redirect_after_login=%2FGewisLtd"}
                                                    className="fa fa-twitter"
                                                />
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={"https://www.linkedin.com/company/gewis-renew-power"}
                                                    className="fa fa-linkedin"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">
                                            Contact Us
                                        </h4>
                                        <ul className="widget_address">
                                            <li>
                                                GeWiS Renewpower - Solar EPC Company,
                                                705-707, Nyati Emporius,
                                                Radha Chowk, Baner
                                            </li>
                                            <li>Pune-411045, India</li>
                                            <li>+91-20-67122400</li>
                                            <li>+91 9112249982</li>
                                            <li>Email: <a href="mailto:contact@gewisrenew.com" style={{color:"white"}}>contact@gewisrenew.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3 ">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">
                                            Useful links
                                        </h4>
                                        <ul>
                                            <li>
                                                <NavLink to={"/about"}>
                                                    About
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/project-grid"}>
                                                    Projects
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/Project-Carousel"}>
                                                    Services
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/contactus"}>
                                                    Contact Us
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* NEWSLETTER */}
                            </div>

                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper ">
                        <div className="overlay-main " />
                        <div className="container ">
                            <div className="row">
                                <div className="mt-footer-bot-right ">
                                    <span className="copyrights-text ">
                                        Copyright © 2024 <strong>GeWiS Renewpower Pvt Ltd.</strong> ALL Rights Reserved.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
